// 调用方法：
// this.start('apppe7fxez6rjdn', 'f6ieszre7fxp', '1', '4')
import { loadScript } from '@/utils/util'
// import { genToken } from './tokenHelper'
export default {
  data() {
    return {
      feedidList: [],
      // 百度RTC参数
      rtcParams: {
        defaultDeviceID: null,
        gDoPing: false,
        gDoPong: false,
        gPingStartupTime: 8000,
        gPingRepeatTime: 5000,
        gAsPublisher: false,
        gShareScreen: false,
        gRtmpmix: false,
        gRtmpMixLayoutIndex: '',
        gRecording: false,
        // 是否自动订阅视频，还是手动订阅
        // 默认值是true，即远端视频注册中心如果有上线，则自动显示在相应预定义的DIV中
        // 如果要手动设置，则要在方法remotevideocoming中设置，即BRTC_SubscribeStreaming('therevideo10', id)
        gAutoSubScribe: false,
        gAutoPlayMuted: false,
        gAutoPublish: false,
        gUsingData: true,
        gUsingVideo: true,
        gUsingAudio: true,
        gReceivingVideo: true,
        gReceivingAudio: true,
        gShowVideobps: true,
        gVideoProfile: 'hires',
        gScreenProfile: 'raw',
        gWaitPermissionTimeOutMs: 30000,
        gCandidateIP: null,
        gCandidatePort: null,
        gMediaServerIP: null,
        gShowSpinner: true,
        gUserEvent: true,
        gSessionEvent: true,
        gAutologin: false,
        // 'playing'
        gState: 'init',
        gBitRate: 1500,
        gAudioBitRate: null,
        gAudioStereo: false,
        gPreviewLocal: false,
        gUsingMessageAPI: false,
        gVolumeMonitor: false,
        gMirrorLocalVideo: true,
        gReportMonitorData: false,
        gReportEnv: 'online',
        gDynamicRemoteVideoView: false,
        gLog: false,
        gName: 'brtc webclient',
        gMultiStreamSDK: false,
        gVideoCodec: 'h264', // 'vp8', 'vp9', 'h263', 'h265', 'h266', 'av1'
        gAudioCodec: '', // 'opus', 'pcmu', 'pcma', 'g722', 'isac16', 'isac32'
        gMuted: false,
        gVideoMuted: false,
        gPublished: true,
        roomname: '1',
        appid: 'apppe7fxez6rjdn',
        token: '004768b2fef5c39e9c518ad02765144187c57dbec301685437685yx1x6p6R4841111285',
        brtcserver: 'wss://rtc.exp.bcelive.com/janus',
        rtmpserver: '',
        rtmpmixtemplate: '',
        userid: '2'
      }
    }
  },
  created() {},
  methods: {
    loadRtcScript() {
      // 动态加载
      if (!window.baidurtc) {
        loadScript('https://brtc-sdk.cdn.bcebos.com/npm/baidurtc@1.2.4/baidu.rtc.sdk.js', () => {})
      }
    },
    // start(appid, appkey, roomname, userid) {
    start(domId, index = 0) {
      if (!window.baidurtc) {
        this.loadRtcScript()
        setTimeout(() => {
          console.log('brtc', this.brtc)
          this.start(domId, index)
        }, 500)
      } else {
        const appid = this.brtc.appId
        const roomname = this.brtc.roomName
        const token = this.brtc.userTokens[index].token
        const userid = this.brtc.userTokens[index].userId

        // const token = genToken('apppe7fxez6rjdn', 'f6ieszre7fxp', '1', 100)
        // const appid = 'apppe7fxez6rjdn'
        // const roomname = '1'
        // const userid = 100
        console.log(`appid=${appid},roomname=${roomname},token=${token},userid=${userid}`)
        window.BRTC_Start({
          debuglevel: false, // true, false, 'all', ['debug','log','error']
          server: 'wss://rtc.exp.bcelive.com/janus',
          appid: appid,
          token: token,
          roomname: roomname,
          videocodec: this.rtcParams.gVideoCodec, // 'h264', 'vp8', 'vp9', 'h263', 'h265', 'h266', 'av1'
          audiocodec: this.rtcParams.gAudioCodec, // 'opus', 'pcmu', 'pcma', 'g722', 'isac16', 'isac32'
          userid: userid,
          displayname: this.rtcParams.gName + '-' + userid,
          remotevideoviewid: 'therevideo',
          localvideoviewid: 'herevideo',
          remotevideoviewid2: 'therevideo2',
          remotevideoviewid3: 'therevideo3',
          remotevideoviewid4: 'therevideo4',
          remotevideoviewid5: 'therevideo5',
          mirrorlocalvideo: this.rtcParams.gMirrorLocalVideo,
          aspublisher: this.rtcParams.gAsPublisher,
          usingdatachannel: this.rtcParams.gUsingData,
          usingvideo: this.rtcParams.gUsingVideo,
          usingaudio: this.rtcParams.gUsingAudio,
          receivingvideo: this.rtcParams.gReceivingVideo,
          receivingaudio: this.rtcParams.gReceivingAudio,
          bitrate: this.rtcParams.gBitRate,
          screenbitrate: this.rtcParams.gBitRate,
          audiobitrate: this.rtcParams.gAudioBitRate,
          audiostereo: this.rtcParams.gAudioStereo,
          showvideobps: this.rtcParams.gShowVideobps,
          sharescreen: this.rtcParams.gShareScreen,
          audiodeviceid: null,
          videodeviceid: this.rtcParams.defaultDeviceID,
          audiooutputdeviceid: null,
          rtmpserver: this.rtcParams.rtmpserver,
          rtmpmixtemplate: this.rtcParams.rtmpmixtemplate,
          rtmpmix: this.rtcParams.gRtmpmix,
          rtmpmixlayoutindex: this.rtcParams.gRtmpMixLayoutIndex,
          recording: this.rtcParams.gRecording,
          autosubscribe: this.rtcParams.gAutoSubScribe,
          autoplaymuted: this.rtcParams.gAutoPlayMuted,
          autopublish: this.rtcParams.gAutoPublish,
          autoresubscribing: !this.rtcParams.gAutoSubScribe,
          linkdownupthreshold: 50,
          waitpermissiontimeoutms: this.rtcParams.gWaitPermissionTimeOutMs,
          reportmonitordata: this.rtcParams.gReportMonitorData,
          reportenv: this.rtcParams.gReportEnv,
          candidateip: this.rtcParams.gCandidateIP,
          candidateport: this.rtcParams.gCandidatePort,
          mediaserverip: this.rtcParams.gMediaServerIP,
          videoprofile: this.rtcParams.gVideoProfile,
          screensharevideoprofile: this.rtcParams.gScreenProfile,
          multistream: this.rtcParams.gMultiStreamSDK,
          remotevideoloading: function (idx) {
            console.log('remotevideoloading, index:' + idx)
          },
          // 用户加入房间的事件，此时用户还没有发布流
          userevent_joinedroom: function (id, display, attribute) {
            console.log('userevent_joinedroom id: ' + id + ', display: ' + display + ', attribute:' + attribute)
          },
          // 用户离开房间，用户已经关闭了流或者没有发布过流
          userevent_leavingroom: function (id, display) {
            console.log('userevent_leavingroom id: ' + id + ', display: ' + display)
          },
          remotevideoon: function (idx) {
            console.log('远端视频流到达的回调, index:' + idx)
          },
          remotevideooff: function (idx) {
            console.log('远端视频流离开的回调, index:' + idx)
          },
          // id是指远程摄像头UserID
          remotevideocoming: (id, display, attribute) => {
            console.log('远端用户流上线的回调, feedid:' + id + ':' + display + ':' + attribute)
            // const sel = document.getElementById('feedid')
            // var ua = navigator.userAgent.toLowerCase()
            // // 是否动态订阅，即自动显示在远程视频区
            // if (!this.rtcParams.gAutoSubScribe && this.rtcParams.gDynamicRemoteVideoView) {

            // } else if (id === 1111) {
            //   // 自定义位置
            //   BRTC_SubscribeStreaming('therevideo10', id)
            // }
            // FRONT_CAMERA: 'CAMERA_FRONT_LONG'   0,
            // LEFT_CAMERA: 'CAMERA_LEFT_FISH',    7
            // RIGHT_CAMERA: 'CAMERA_RIGHT_FISH',  11
            // BACK_CAMERA: 'CAMERA_BACK_WIDE'     14
            if ([100, 107, 111, 114].indexOf(id) < 0) {
              console.log(`id=${id}取消订阅视频流`)
              return
            }
            let vDomId = 'therevideo2'
            const domIdMap = {
              therevideo: 107,
              therevideo2: 100,
              therevideo3: 111,
              therevideo4: 114
            }
            if (domId) {
              vDomId = domId
              if (this.feedidList.includes(domIdMap[domId])) {
                return
              }
              window.BRTC_SendMessageToUser('OPEN', domIdMap[domId])
              this.feedidList.push(domIdMap[domId])
            } else {
              window.BRTC_SendMessageToUser('OPEN', id)
              this.feedidList.push(id)
              if (id === 107) {
                vDomId = 'therevideo'
              } else if (id === 100) {
                vDomId = 'therevideo2'
              } else if (id === 111) {
                vDomId = 'therevideo3'
              } else if (id === 114) {
                vDomId = 'therevideo4'
              }
            }
            console.log(`展示Dom,vDomId=${vDomId}`)
            const feedid = domId ? domIdMap[domId] : id
            console.log(`订阅视频流,id=${feedid}`)
            window.BRTC_SubscribeStreaming(vDomId, feedid)
          },
          remotevideoleaving: function (id) {
            console.log('远端用户流离开的回调, feedid:' + id)
          },
          remotevideounpublished: function (id) {
            console.log('remotevideounpublished, feedid:' + id)
          },
          // 心跳管理???
          remotedata: function (data, label) {
            // console.log('got remote data:' + data)
            // $('#remotedata').append(data)
            if (this.rtcParams.gDoPong) {
              window.BRTC_SendData('pong data.')
            } else if (this.rtcParams.gDoPing) {
              setTimeout(function () {
                window.BRTC_SendData('new ping.')
              }, this.rtcParams.gPingRepeatTime)
            }
          },
          // BRTC_Start()成功
          success: () => {
            if (this.rtcParams.gDoPing) {
              setTimeout(() => {
                window.BRTC_SendData('ping data.')
              }, this.rtcParams.gPingStartupTime)
            }

            if (this.rtcParams.gAutologin) {
              // Cookies.set('state', 'playing')
            }
            if (this.rtcParams.gUsingMessageAPI) {
              window.BRTC_SetUserAttribute('{name:"red",tel:"123456789"}')
            }

            if (this.rtcParams.gVolumeMonitor) {
              setInterval(function () {
                var r = window.BRTC_GetRemoteAudioLevels()

                r.forEach((e) => {
                  console.log('BRTC_GetRemoteAudioLevels: ' + JSON.stringify(e))
                  if (e.volume === 0) {
                    console.error('GetRemoteAudioLevels volume is 0: ' + JSON.stringify(e))
                    console.error(e)
                  }
                })
              }, 5000)
            }
          },
          // 本地视频开始发布的回调
          localvideopublishing: function () {},
          // 本地视频成功发布到回调
          localvideopublished_ok: function () {
            // if (gRequest['canvas']) {
            //   const canvas = document.querySelector('canvas')
            //   BRTC_ReplaceStream(canvas.captureStream(15))
            // }
          },
          // BRTC_Start()失败，或运行过程中出现了错误
          error: function (error) {
            alert('BRTC: ' + error)
            // window.location.reload()
            // $('#start').removeAttr('disabled')
          },
          // 运行过程中出现错误被销毁的回调
          destroyed: function (error) {
            console.log('error', error)
            // window.location.reload()
          },
          // 本地视频流自动， name是流名称， 相机是'camera'或屏幕共享'screen'
          onlocalstream: function (stream, name) {
            // local stream for display sonic wave
            console.log('onlocalstream name: ' + name)
          },
          // 本地视频流关闭， name是流名称， 相机是'camera'或屏幕共享'screen'
          onlocalstream_end: function (name) {
            // end event of local stream
            console.log('onlocalstream_end name: ' + name)
          },
          localvideopublished_closed: function () {
            console.log('localvideopublished_closed by server, please do StartPublish again')
          },
          remotevideo_closed: function (feedid) {
            console.log('remotevideo_closed(feedid: ' + feedid + ') by server, please do SubScribing again')
          },
          // 消息事件回调{msg.id,msg.data}
          onmessage: function (msg) {
            // event onmessage.
            // console.log('onmessage id: ' + msg.id + ' data: ' + msg.data)
            // $('#remotedata').append(msg.data)
          },
          // 属性事件回调{a.id,a.attribute}
          onattribute: function (a) {
            // event onattribute
            console.log('onattribute id: ' + a.id + ' attribute: ' + a.attribute)
          }
        })
      }
    }
  },
  beforeDestroy() {
    if (window.baidurtc) {
      if (this.feedidList.length > 0) {
        for (let i = 0; i < this.feedidList.length; i++) {
          window.BRTC_SendMessageToUser('CLOSE', this.feedidList[i])
          // window.BRTC_StopSubscribeStreaming(this.feedidList[i])
        }
      }
      window.BRTC_Stop()
    }
  }
}
